@import './transition.less';

body {
  padding-top: constant(safe-area-inset-top); /* iOS < 11.2 */
  padding-top: env(safe-area-inset-top); /* iOS >= 11.2 */
  height: calc(100% - constant(safe-area-inset-top)); /* iOS < 11.2 */
  height: calc(100% - env(safe-area-inset-top)); /* iOS >= 11.2 */
  height: 100%;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
}

html {
  height: 100%;
  box-sizing: border-box;
}
#root {
  height: 100%;
}
.ant-layout {
  height: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.no-padding {
  padding: 0px !important;
}

.padding-content {
  padding: 4px 0;
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  // color: inherit;
  text-decoration: none;
}

div:focus {
  outline: none;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.pr-5 {
  padding-right: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.inlineBlock {
  display: block;
}

.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

.app-container {
  padding: 20px;
}
@media (max-width: 480px) {
  .ant-layout-sider {
    flex: 0 0 120px !important;
    max-width: 120px !important;
    min-width: 120px !important;
    width: 120px !important;
  }
  .ant-layout-sider-collapsed {
    flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    width: 60px !important;
  }
}

@primary-color: #1DA57A;